import Vue from 'vue';
import VueRouter from 'vue-router';

import DelixPlus from '@/views/booth/DelixPlus.vue';
import Plavix from '@/views/booth/Plavix.vue';
import Live from '@/views/Live.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/:slug',
    name: 'Live',
    component: Live,
  },
  {
    path: '/:slug/booth/delixplus',
    name: 'DelixPlus',
    component: DelixPlus,
  },
  {
    path: '/:slug/booth/plavix',
    name: 'Plavix',
    component: Plavix,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
